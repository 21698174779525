
import { ClockCircleOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: ["timelineJson"],
  components: {
    ClockCircleOutlined,
  },
  mounted() {
    console.log(this.timelineJson);
  },
});
