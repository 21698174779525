<template>
  <div>
    <!-- 顶部固定头部 -- 开始 -->
    <Header
      ref="headerComponents"
      id="Header"
      class="headerNavBar"
      :headerMenuJson="LogJson.header.menuItem"
      @update:itemClick="itemClick"
    />
    <!-- 顶部固定头部 -- 结束 -->
    <!-- 日志（时间轴） -- 开始 -->
    <Timeline
      style="margin: 50px 200px"
      id="log"
      :timelineJson="LogJson.timeline"
    />
    <!-- 日志（时间轴） -- 结束 -->

    <!-- 关于（抽屉） -- 开始 -->
    <Drawer ref="drawerComponents" :drawerJson="LogJson.drawer" />
    <!-- 关于（抽屉） -- 结束 -->

    <!-- 底部 -- 开始 -->
    <div style="width: 100%; position: absolute; bottom: 0px">
      <a-layout-footer style="text-align: center">
        ©2022 学點-YellowCan&nbsp; &nbsp;
        <a href="https://beian.miit.gov.cn" target="_blank"
          >粤ICP备2022146118号-1</a
        >&nbsp; &nbsp;
        <!-- <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
          target="_blank"
          >粤公网安备 xxx号</a
        > -->
      </a-layout-footer>
    </div>
    <!-- 底部 -- 结束 -->
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/Header.vue";
import LogJson from "@/views/Log/log.json";
import Timeline from "@/components/Timeline.vue";
import Drawer from "@/components/Drawer.vue";
import globalVariableJson from "@/global/globalVariable/globalVariable.json";
export default defineComponent({
  name: "Log",
  components: {
    Header,
    Timeline,
    Drawer,
  },
  data() {
    return {
      globalVariable: {},
      LogJson: LogJson,
      inner_Height: null,
    };
  },
  setup() {
    const drawerComponents = ref();
    return {
      drawerComponents,
    };
  },

  methods: {
    //全局参数变量
    globalVariableJson(globalVariableJson) {
      // console.log("globalVariableJson");
      // console.log(globalVariableJson);
      this.globalVariable = globalVariableJson;
      //console.log(this.globalVariable);
    },
    //打开抽屉
    itemClick(item) {
      console.log(item);
      if (item.click === "showDrawer") {
        this.drawerComponents[item.click]();
      } else {
        //this[item.click]();
        this.go(item.key);
      }
    },
    go(key) {
      document.getElementById(key).scrollIntoView({ behavior: "smooth" });
      /*或
      document.querySelector("#idshow").scrollIntoView();
  	  或
  	  this.$refs.view.scrollIntoView();
  	  */
    },
  },
  mounted() {
    //window.onresize = function () {};
  },
});
</script>

<style lang="less" scoped>
.ant-layout-footer {
  padding: 24px 50px;
  //color: rgba(0, 0, 0, 0.85);
  color: #fff;
  font-size: 14px;
  background: inherit;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.8);
}
</style>
