<template>
  <div class="home">
    <!-- 轮播画布（走马灯） -- 开始 -->
    <Carousel
      ref="carouseComponents"
      id="home"
      class="carouse"
      :carouselJson="HomeJson.header.carousel"
    />
    <!-- 轮播画布（走马灯） -- 结束 -->
    <!-- 顶部固定头部 -- 开始 -->
    <Header
      ref="headerComponents"
      id="Header"
      class="headerNavBar"
      :headerMenuJson="HomeJson.header.menuItem"
      @update:itemClick="itemClick"
    />
    <!-- 顶部固定头部 -- 结束 -->
    <div style="position: absolute; top: 50px; text-align: center; width: 100%">
      <a-typography-title class="describeTitle">学无止境</a-typography-title>
      <a-typography-paragraph class="describe">
        #欢迎来到学點Data站#
      </a-typography-paragraph>
    </div>

    <!-- 主题（固钉） -- 开始 -->
    <ThemeAffix
      @globalVariableJson="globalVariableJson"
      @click="themeAffixClick"
    />
    <!-- 主题（固钉） -- 开始 -->

    <div
      id="skill"
      style="
        margin-top: 80px;
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 80px;
      "
    >
      <List />
    </div>

    <!-- 关于（抽屉） -- 开始 -->
    <Drawer ref="drawerComponents" :drawerJson="HomeJson.drawer" />
    <!-- 关于（抽屉） -- 结束 -->

    <!-- 作品、经历 -- 开始 -->
    <div id="workAndSexperience" class="words">
      <a-typography-title style="margin-top: 15px"
        >Works and Experiences</a-typography-title
      ><Card :cardJson="HomeJson.card" />
    </div>
    <!-- 作品、经历 -- 结束 -->

    <!-- 日志（时间轴） -- 开始 -->
    <!-- <Timeline id="log" :timelineJson="HomeJson.timeline" /> -->
    <!-- 日志（时间轴） -- 结束 -->

    <!-- 底部 -- 开始 -->
    <div style="width: 100%">
      <a-layout-footer style="text-align: center">
        ©2023 学點-YellowCan&nbsp; &nbsp;
        <a href="https://beian.miit.gov.cn" target="_blank"
          >粤ICP备2022146118号-1</a
        >&nbsp; &nbsp;
        <!-- <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
          target="_blank"
          >粤公网安备 xxx号</a
        > -->
      </a-layout-footer>
    </div>
    <!-- 底部 -- 结束 -->
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/Header.vue";
import ThemeAffix from "@/components/ThemeAffix.vue";
import Carousel from "@/components/Carousel.vue";
import HomeJson from "@/views/Home/home.json";
import Drawer from "@/components/Drawer.vue";
import Card from "@/components/Card.vue";
//import Timeline from "@/components/Timeline.vue";
import List from "@/components/List.vue";
import globalVariableJson from "@/global/globalVariable/globalVariable.json";
export default defineComponent({
  name: "Home",
  components: {
    Header,
    ThemeAffix,
    Carousel,
    Drawer,
    Card,
    //Timeline,
    List,
  },
  data() {
    return {
      globalVariable: {},
      HomeJson: HomeJson,
      inner_Height: null,
    };
  },
  setup() {
    const headerComponents = ref();
    const drawerComponents = ref();
    const carouseComponents = ref();

    return {
      headerComponents,
      drawerComponents,
      carouseComponents,
    };
  },

  methods: {
    //全局参数变量
    globalVariableJson(globalVariableJson) {
      // console.log("globalVariableJson");
      // console.log(globalVariableJson);
      this.globalVariable = globalVariableJson;
      //console.log(this.globalVariable);
    },
    //固钉点击事件
    themeAffixClick() {
      this.$nextTick(() => {
        this.headerComponents.reload();
      });
    },
    //打开抽屉
    itemClick(item) {
      console.log(item);
      if (item.click === "showDrawer") {
        this.drawerComponents[item.click]();
      } else {
        //this[item.click]();
        this.go(item.key);
      }
    },
    SheZhiPX() {
      //this.carouseComponents.height= window.innerHeight;
      this.inner_Height = this._isMobile() ? "100%" : window.innerHeight + "px";
      globalVariableJson.headerTheme = this._isMobile() ? "dark" : "light";
      //dom.height= window.innerHeight+"px";
      //dom.css("height", window.innerHeight + "px");
      //dom.style.height = window.innerHeight;
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    go(key) {
      document.getElementById(key).scrollIntoView({ behavior: "smooth" });
      /*或
      document.querySelector("#idshow").scrollIntoView();
  	  或
  	  this.$refs.view.scrollIntoView();
  	  */
    },
  },
  mounted() {
    this.SheZhiPX();
    //window.onresize = function () {};
  },
});
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.headerNavBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.carouse {
  width: 100%;
  //height: 100%;
  //position: fixed;
}

#carousel .ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  height: 100% !important;
}

#carousel .ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  height: 100% !important;
}
#carousel .ant-carousel .slick-initialized {
  height: 100% !important;
}
.describeTitle {
  color: rgb(255, 255, 255);
  //position: absolute;
  //top: 40%;
  //text-align: center;
  //width: 100%;
}
.describe {
  color: rgb(255, 255, 255);
  //position: absolute;
  //top: 48%;
  //text-align: center;
  //width: 100%;
}
:deep(.slick-list) {
  height: v-bind(inner_Height);
}
.words {
  margin-top: 80px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 80px;
  background: hsla(0, 0%, 100%, 0.25) border-box;
  overflow: hidden;
  border-radius: 0.5em;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.3) inset,
    0 0.5em 1em rgba(0, 0, 0, 0.6);
}
.ant-layout-footer {
  padding: 24px 50px;
  //color: rgba(0, 0, 0, 0.85);
  color: #fff;
  font-size: 14px;
  background: inherit;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.8);
}
:deep(.ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content, .ant-timeline.ant-timeline-right
    .ant-timeline-item-left
    .ant-timeline-item-content, .ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content) {
  left: calc(50% - 4px);
  width: calc(50% - 25px);
  text-align: left;
}
:deep(.ant-menu-submenu-popup .ant-menu) {
  background-color: transparent;
  box-shadow: inset 0 0 0 200px rgb(0 0 0 / 0.3);
}
</style>
