
import { defineComponent, reactive, ref, toRefs } from "vue";
// import {
//   MailOutlined,
//   AppstoreOutlined,
//   SettingOutlined,
// } from "@ant-design/icons-vue";
import type { MenuMode, MenuTheme } from "ant-design-vue";
import globalVariableJson from "../global/globalVariable/globalVariable.json";
import { Content } from "ant-design-vue/lib/layout/layout";
export default defineComponent({
  props: ["headerMenuJson"],
  components: {
    //MailOutlined,
    //AppstoreOutlined,
    //SettingOutlined,
  },
  data() {
    return {
      globalVariable: this.globalVariableJson,
      theme: globalVariableJson.headerTheme as MenuTheme,
      headerMenu: this.headerMenuJson,
      headerMenuItem: null,
    };
  },
  setup(props, content) {
    const current = ref<string[]>([""]);
    //打开显示抽屉
    function menuItemClick(item: any) {
      //console.log("menu click");
      //console.log(item);
      // if (item.click === "showDrawer") {
      //   showDrawer();
      // }
      itemClick(item);
    }
    //打开显示抽屉
    function itemClick(item) {
      //console.log("guanyu");
      content.emit("update:itemClick", item);
    }
    return {
      current,
      menuItemClick,
      itemClick,
    };
  },
  watch: {
    "globalVariableJson.headerTheme": {
      deep: true,
      //newValue和oldValue为data变化前后的新值和旧值，也可只写newValue或者什么也不写，看具体想要执行的逻辑。变量名仅供参考
      handler(newValue, oldValue) {
        //此处插入想要执行的代码块
        // console.log("newValue");
        // console.log(newValue);
        // console.log("oldValue");
        // console.log(oldValue);
        this.theme = newValue as MenuTheme;
      },
    },
  },
  mounted() {
    //console.log(this.headerMenuJson);
  },
  methods: {
    //重载
    reload() {
      //   console.log("reload");
      // console.log(globalVariableJson);
      this.theme = globalVariableJson.headerTheme as MenuTheme;
    },
    //打开新页面
    openNewHref(URL: any, name: any) {
      window.open(URL, name);
    },
  },
});
