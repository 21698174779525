import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26ec98fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "iconTheme" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.affixClass),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.themeChange && _ctx.themeChange(...args))),
    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
    style: {"position":"fixed","bottom":"20px","right":"20px"}
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.testIcon), 1)
  ], 34))
}