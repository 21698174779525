
import {
  SettingOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: ["cardJson"],
  components: {
    SettingOutlined,
    EditOutlined,
    EllipsisOutlined,
  },
});
