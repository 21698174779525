import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1834ee69"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    class: "custom-class",
    style: {"color":"red"},
    title: _ctx.drawerJson.title,
    placement: "right",
    onAfterVisibleChange: _ctx.afterVisibleChange
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drawerJson.card, (itemCard, index) => {
          return (_openBlock(), _createBlock(_component_a_card, {
            title: itemCard.title,
            key: index
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemCard.content, (item) => {
                return (_openBlock(), _createElementBlock("div", { key: item }, [
                  (item.paragraphBool)
                    ? (_openBlock(), _createBlock(_component_a_typography_paragraph, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.paragraph) + " ", 1),
                          (item.paragraphTextBool)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.paragraphText, (itemText) => {
                                  return (_openBlock(), _createBlock(_component_a_typography_text, {
                                    key: itemText,
                                    strong: itemText.strong ? true : false,
                                    mark: itemText.mark ? true : false,
                                    code: itemText.code ? true : false,
                                    style: {"display":"block"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(itemText.text), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["strong", "mark", "code"]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["title"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["visible", "title", "onAfterVisibleChange"]))
}