
import { defineComponent } from "vue";
// const data: any = [
//   {
//     title: "- Web 开发：",
//     Data: [
//       "熟悉 Html、Css、JavaScript、jQuery、Vue 等前端语言和框架",
//       "熟悉 JavaScript 规范：ES5、ES6（ES6 代码简洁易懂，ES5 代码复杂；但是 ES6 浏览器兼容性很差，ES5 浏览器兼容性很好；一般使用 ES6 编写代码，运行时编译成 ES5）",
//     ],
//   },
//   {
//     title: "- 前端 UI 组件库：",
//     Data: [
//       "熟悉 Bootstrap、Layui、Weui、Vant Weapp、Element UI",
//       "了解 Easyui - 后端开发框架：",
//       "熟悉 C#EntityFramework、DotNet Core、Mybatis、Spring、Spring MVC",
//       "熟悉 SqlSugar、FreeSql 等第三方 ORM（对象关系映射框架）",
//       "了解 MyBatis Plus、Spring Boot、Spring Cloud",
//     ],
//   },
//   {
//     title: "- 数据库相关：",
//     Data: [
//       "熟悉 SQLServer、MySQL 关系型数据库",
//       "熟悉 Redis 非关系型数据库",
//       "熟悉 MySql 主从配置、MHA 高可用架构做数据库故障切换",
//       "了解 mysql 优化",
//     ],
//   },
//   {
//     title: "- 服务器及运维相关：",
//     Data: [
//       "熟悉 Linux 系统",
//       "熟悉 Tomcat、Nginx 服务器",
//       "熟悉 Docker 容器部署",
//     ],
//   },
//   {
//     title: "- 编程工具：",
//     Data: [
//       "熟悉 Notepad++、Dreamweaver、Visual Studio、Visual Studio Core、HBuilder X、微信开发者工具、Intellij IDEA",
//     ],
//   },
//   {
//     title: "- 项目开发及管理工具",
//     Data: ["熟悉 SVN、Maven、Git - 接口说明及测试工具", "熟悉 Swagger、Posman"],
//   },
// ];
const data: any = [
  {
    Data: [
      "熟练掌握前端以及后端 dot.net 和 Java 相关知识，多年一线开发开发经验，具备良好的面向对象的编程思想、编程能力，对设计模式和设计原则有深入的理解，对 MVC 和 MVVM 设计模式有独特的见解",
      "熟练使用 Bootstrap、Layui、Weui、Vant Weapp、Element UI、And Design Vue 前端 UI 组件库",
      "熟练使用 jQuery、Vue 前端 JavaScript 库",
      "熟悉 JavaScript 规范：ES5、ES6（ES6 代码简洁易懂，ES5 代码复杂；但是 ES6 浏览器兼容性很差，ES5 浏览器兼容性很好；一般使用 ES6 编写代码，运行时编译成 ES5）",
      "熟练 Dot.Net Core、Java 框架，对 C#EntityFramework、Asp.net MVC、Mybatis、Spring、Spring MVC 以及SqlSugar、FreeSql 第三方 ORM（对象关系映射框架）等都有深入研究",
      "具有 MySql、SqlServer 主流数据库开发设计经验，并且了解 sql 优化，做过 MySql 主从配置，使用 MHA 高可用架构做数据库故障切换",
      "有 Redis 非关系型数据库做分布式锁的经验",
      "熟练使用 Linux 系统，对 Tomcat、Nginx 以及 Docker 容器化部署都有一定的操作经验"
    ],
  }
];

export default defineComponent({
  setup() {
    return {
      data,
    };
  },
});
