import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08014ea7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu_item_group = _resolveComponent("a-menu-item-group")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_menu, {
      selectedKeys: _ctx.current,
      "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current) = $event)),
      theme: _ctx.theme,
      mode: "horizontal",
      id: "headerMenu",
      forceSubMenuRender: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerMenuJson, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.key
          }, [
            (item.type === 'LogItem')
              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.key,
                  style: {"position":"absolute","left":"20px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_image, {
                      width: item.width,
                      src: require('@/assets/images/' + item.url)
                    }, null, 8, ["width", "src"]),
                    _createTextVNode(" " + _toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            (item.type === 'Item')
              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.key,
                  onClick: ($event: any) => (_ctx.menuItemClick(item))
                }, {
                  icon: _withCtx(() => []),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            (item.type === 'ItemDisabled')
              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.key,
                  disabled: ""
                }, {
                  icon: _withCtx(() => []),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            (item.type === 'subItem')
              ? (_openBlock(), _createBlock(_component_a_sub_menu, { key: 3 }, {
                  icon: _withCtx(() => []),
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.menuGroup, (menuGroupItem) => {
                      return (_openBlock(), _createBlock(_component_a_menu_item_group, {
                        title: menuGroupItem[0].menuGroupTitle,
                        key: menuGroupItem
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuGroupItem, (ItemGroup) => {
                            return (_openBlock(), _createBlock(_component_a_menu_item, { key: ItemGroup }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(ItemGroup.title), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["title"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            (item.type === 'LinkItem')
              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: 4,
                  onClick: ($event: any) => (_ctx.openNewHref(item.href,item.target))
                }, {
                  icon: _withCtx(() => []),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(item.title) + " ", 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["selectedKeys", "theme"])
  ]))
}