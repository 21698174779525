
import { defineComponent } from "vue";
import globalVariableJson from "../global/globalVariable/globalVariable.json";

export default defineComponent({
  components: {},
  data() {
    return {
      testIcon: "☀",
      affixClass: "affixSun",
    };
  },
  setup() {
    const change = (affixed: boolean) => {
      console.log(affixed);
    };
    return {
      change,
    };
  },
  methods: {
    //改变icon内容
    themeChange() {
      this.testIcon = this.testIcon === "☀" ? "🌙" : "☀";
      this.affixClass =
        this.affixClass === "affixSun" ? "affixMoon" : "affixSun";
      globalVariableJson.headerTheme =
        globalVariableJson.headerTheme === "light" ? "dark" : "light";//修改全局变量
      //console.log(globalVariableJson.headerTheme)
      //this.$emit("globalVariableJson", globalVariableJson);
    },
  },
  mounted() {
    //this.$emit("globalVariableJson", globalVariableJson);
  },
});
