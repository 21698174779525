import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-245b84e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gutter-example" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setting_outlined = _resolveComponent("setting-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_ellipsis_outlined = _resolveComponent("ellipsis-outlined")!
  const _component_a_card_meta = _resolveComponent("a-card-meta")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_col = _resolveComponent("a-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardJson, (item) => {
      return (_openBlock(), _createBlock(_component_a_col, {
        class: "gutter-row",
        key: item
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_card, {
            hoverable: "",
            class: "card",
            style: {}
          }, {
            cover: _withCtx(() => [
              _createElementVNode("img", {
                alt: "example",
                src: 
              item.localImg
                ? require('@/assets/images/' + item.imgUrl)
                : item.imgUrl
            
              }, null, 8, _hoisted_2)
            ]),
            actions: _withCtx(() => [
              (item.setting.show)
                ? (_openBlock(), _createBlock(_component_setting_outlined, { key: "setting" }))
                : _createCommentVNode("", true),
              (item.edit.show)
                ? (_openBlock(), _createBlock(_component_edit_outlined, { key: "edit" }))
                : _createCommentVNode("", true),
              (item.ellipsis.show)
                ? (_openBlock(), _createBlock(_component_ellipsis_outlined, { key: "ellipsis" }))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_card_meta, {
                style: {"width":"100%"},
                title: item.title,
                description: item.description
              }, null, 8, ["title", "description"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}