
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: ["drawerJson"],
  data() {
    return {
      //drawer:this.drawer,
    };
  },
  setup() {
    const visible = ref<boolean>(false);

    const afterVisibleChange = (bool: boolean) => {
      //console.log('visible', bool);
    };

    const showDrawer = () => {
      visible.value = true;
    };

    return {
      visible,
      afterVisibleChange,
      showDrawer,
    };
  },
  mounted() {
    console.log(this.drawerJson);
  },
});
