import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClockCircleOutlined = _resolveComponent("ClockCircleOutlined")!
  const _component_a_timeline_item = _resolveComponent("a-timeline-item")!
  const _component_a_timeline = _resolveComponent("a-timeline")!

  return (_openBlock(), _createBlock(_component_a_timeline, {
    mode: _ctx.timelineJson.mode,
    position: _ctx.timelineJson.position
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timelineJson.timeline, (item) => {
        return (_openBlock(), _createBlock(_component_a_timeline_item, {
          color: 
        item.color === 'red' ? 'red' : item.color === 'green' ? 'green' : 'blue'
      ,
          key: item
        }, _createSlots({
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(item.text), 1)
          ]),
          _: 2
        }, [
          (item.dot)
            ? {
                name: "dot",
                fn: _withCtx(() => [
                  _createVNode(_component_ClockCircleOutlined, { style: {"font-size":"16px"} })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["color"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["mode", "position"]))
}