import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22742968"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "carousel" }
const _hoisted_2 = {
  class: "custom-slick-arrow",
  style: {"left":"10px","z-index":"1"}
}
const _hoisted_3 = {
  class: "custom-slick-arrow",
  style: {"right":"10px"}
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_circle_outlined = _resolveComponent("left-circle-outlined")!
  const _component_right_circle_outlined = _resolveComponent("right-circle-outlined")!
  const _component_a_carousel = _resolveComponent("a-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_carousel, { arrows: "" }, {
      prevArrow: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_left_circle_outlined)
        ])
      ]),
      nextArrow: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_right_circle_outlined)
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carouselJson.isCarouselImg
          ? _ctx.carouselJson.carouselImg
          : [], (item, key) => {
          return (_openBlock(), _createElementBlock("div", { key: key }, [
            _createElementVNode("img", {
              style: {"width":"100%","height":"100%"},
              src: item.url,
              alt: ""
            }, null, 8, _hoisted_4)
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}