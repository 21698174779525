
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, reactive, HtmlHTMLAttributes } from "vue";
export default defineComponent({
  props: ["carouselJson"],
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  data() {
    return {
      slickSlideWidth: "auto !importan",
    };
  },
  setup() {
    const slickSlideDom = document.getElementsByClassName("slick-slide");
    const Ref = ref<any>();
    // let slickSlideWidth = reactive({
    //   width: 'auto !importan',
    // });
    return {
      slickSlideDom,
      Ref,
      //slickSlideWidth,
    };
  },
  mounted() {
    //console.log(this.rmStyle);
    //console.log(this.rmStyle.style);
    //console.log(this.rmStyle.namedItem);
    // console.log(this.$el);
    // console.log(this.$el.getElementsByClassName("slick-slide"));
    // console.log(document.getElementsByClassName("slick-slide"));

    // this.$el.getElementsByClassName("slick-slide").style.width("auth !importan");
    // this.$el.getElementsByClassName("slick-slide");
    // console.log(this.slickSlideDom);
    this.Ref
  },
});
